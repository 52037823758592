export default {
    state: {
        account: []
    },
    mutations: {
        updAccount(state, account) {
            state.account = account
        },
    },
    actions: {
        async fetchAccount({commit}) {
            const res = await fetch(this.state.url + 'auth/users/me/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Accept': 'application/json',
                    "authorization": "JWT " + localStorage.getItem('access_token')
                },
            });
            const account = await res.json()
            // console.log(account)
            commit('updAccount', account)
        },
    },
    getters: {
        getAccount(state) {
            return state.account
        },
    }
}

