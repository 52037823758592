import Vue from 'vue'
import Vuex from 'vuex'
import account from "@/store/modules/account";


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // url: 'http://apk.bmipk.ru/',
        url: 'https://apk.bmipk.ru/',
        // url: 'http://127.0.0.1:8000/'
    },
    mutations: {},
    actions: {},
    getters: {
        getUrl(state) {
            return state.url
        }
    },
    modules: {
        account,
    }
})
