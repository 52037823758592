<template>
  <b-card-text>
    <div>
      <div class="selectTheme">
        <div class="name_test row">
          <div class="col-sm-3 row">
            <div>Название теста</div>
          </div>
          <div class="col-sm-9 row">
            <div class="col-7">
              <b-form-input v-model="appTest.new_name_test"></b-form-input>
            </div>
            <div class="col-3">Время на тест</div>
            <div class="col-2">
              <b-form-input v-model="appTest.name_test_time" type="number"></b-form-input>
            </div>
          </div>
        </div>
        <div class="courses_ids row">
          <div class="col-3">Курсы(Пример 131,132,155)</div>
          <div class="col-9">
            <b-form-input v-model="appTest.courses_ids"></b-form-input>
          </div>
        </div>
        <div class="theme row">
          <div class="col-sm-3 row">
            <div>Название Темы</div>
          </div>
          <div class="col-sm-9 row">
            <div>
              <b-form-input v-model="appTest.new_theme"></b-form-input>
            </div>
          </div>

        </div>
        <div class="subTheme">
          <div class="col-sm-3 row">
            <div>Название подтемы</div>
          </div>
          <div class="col-sm-9 row">
            <div>
              <b-form-input v-model="appTest.new_subtheme"></b-form-input>
            </div>
          </div>
        </div>
      </div>
      <div class="card" style="background: lightgray">Вопросы/ответы</div>
      <b-form-textarea
          id="textarea"
          v-model="text"
          placeholder="Вопросы,ответы"
          rows="10"
      ></b-form-textarea>
      <button @click="processing">Обработать</button>
    </div>
    <div class="tech_info" style="text-align: left">
      <div v-for="item in Object.keys(appTest)" style="display: flex">
        <div style="width: 180px">{{ item }} :</div>
        <div> {{ appTest[item] }}</div>
      </div>
    </div>
    <b-modal id="modal-upload-info" no-close-on-backdrop no-close-on-esc title="Информация!!!">
      <p class="my-4">Загружаю, ЖДИ!!!</p>
    </b-modal>
  </b-card-text>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "uploadTextarea",
  data() {
    return {
      text: '(q)3. На какие из перечисленных ОПО распространяются требования ФНП ПС?\n' +
          '|+На ОПО, где эксплуатируются подъемные сооружения, установленные в шахтах.\n' +
          '|На ОПО, где эксплуатируются эскалаторы.\n' +
          '|На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления.\n' +
          '|На ОПО, где эксплуатируются краны для подъема и опускания затворов гидротехнических сооружений без осуществления зацепления их крюками, оборудованные единственным механизмом подъема и не имеющие механизма передвижения крана.\n' +
          '|На ОПО, где эксплуатируются ПС, установленные на судах и иных плавучих средствах.\n' +
          '(q)4. В каком документе содержатся результаты работы комиссии, принимающей решение о возможности пуска ПС в работу?\n' +
          '\n' +
          '|В акте пуска ПС в работу.\n' +
          '|В паспорте ПС.\n' +
          '|+В ППР.\n' +
          '|В руководстве (инструкции) по эксплуатации ПС.\n' +
          '(q)5. На какой высоте над уровнем нижней посадочной площадки (земли) должен находиться груз на неподвижном грузонесущем устройстве при статических испытаниях строительного подъемника?\n' +
          '\n' +
          '|+Не более 250 мм.\n' +
          '|Не более 50 мм.\n' +
          '|Не более 200 мм.\n' +
          '|Не более 150 мм.',
      result: [],
      triggers: {
        custom_test: false,
        custom_theme: false,
        custom_subtheme: false,
        pop0: false,
        pop1: false,
        pop2: false
      },
      options: {},
      appTest: {
        new_name_test: '',
        name_test_time: 20,
        new_theme: '',
        new_subtheme: '',
        questions: [],
        courses_ids: ''
      }
    }
  },
  watch: {},
  methods: {
    ...mapGetters(['getUrl', 'getAccount']),
    processing() {
      this.$bvModal.show('modal-upload-info')
      let res = {
        test: this.appTest.new_name_test,
        courses_ids: this.appTest.courses_ids,
        test_time: this.appTest.name_test_time,
        theme: this.appTest.new_theme,
        subtheme: this.appTest.new_subtheme,
        questions: []
      }
      let question_arr = this.text.split('(q)')
      for (let i = 1; i < question_arr.length; i++) {
        let elem = question_arr[i].replace(/\n/g, '').split('|')
        let item_q = {question: elem[0].trim(), answers: []}
        for (let j = 1; j < elem.length; j++) {
          if (elem[j][0] === '+')
            item_q.answers.push({text: elem[j].trim().slice(1), right: true})
          else
            item_q.answers.push({text: elem[j].trim(), right: false})
        }
        res.questions.push(item_q)
      }
      console.log(res)
      this.postTesting(res)
    },
    async postTesting(data) {
      const res = await fetch(this.getUrl() + 'storage_test/upload-test/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Accept': 'application/json',
          "authorization": "JWT " + localStorage.getItem('access_token')
        },
        body: JSON.stringify(data)
      });
      const response = await res.json()
      this.$bvModal.hide('modal-upload-info')
      console.log(response)
    },

  }
}
</script>

<style scoped lang="scss">
.selectTheme {
  border: 1px solid #0066ae;
  border-radius: 5px;
  padding: 10px;
}

.theme, .subTheme, .name_test {
  display: flex;
  padding: 10px;

  strong {
    width: 200px;
  }
}
</style>