<template>
  <b-modal :id="'modal-center-detail' + result.id" size="xl" centered title="Подробно">
    <div class="result_info">
      <table class="table" style="text-align: center">
        <thead>
        <tr>
          <th scope="col">Оценка %</th>
          <th scope="col">Время теста</th>
          <th scope="col">Правильных ответов</th>
          <th scope="col">Неправильных ответов</th>
        </tr>
        </thead>
        <tbody>
        <tr style="text-align: center">
          <th scope="row"> {{ result.result }} %</th>
          <td>{{ secondToMinutes(result.time_result) }}</td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </table>
      <hr>
    </div>
    <table class="table table-bordered">
      <thead>
      <tr>
        <th scope="col">Вопрос</th>
        <th scope="col">Ответ</th>
        <th scope="col">Правильный ответ</th>
        <th scope="col">Время ответа</th>
      </tr>
      </thead>

      <tbody v-for="(answer,index) in result.result_question" :key="index">
      <tr>
        <td>{{ answer.question.title }}</td>
        <td v-if="answer.answer_question.length">
          <div v-for="(i_answer, index) in answer.answer_question" :key="index"
               :class="[{ answerRight: i_answer.answer.is_right }, 'errorClass']">
            <li>{{ i_answer.answer.title }}</li>
          </div>
        </td>
        <td v-else>
          <div>-</div>
        </td>
        <td v-html="returnRigthAnswer(answer.question.answers)"></td>
        <td></td>
      </tr>
      </tbody>
    </table>
  </b-modal>
</template>

<script>
import convertDate from "@/mixins/convertDate";

export default {
  mixins: [convertDate],
  name: "resultDetail",
  props: ['result'],
  methods: {
    returnRigthAnswer(answers) {
      let res = ''
      let r_answers = answers.filter(elem => elem.is_right === true)
      for (let i = 0; i < r_answers.length; i++)
        res += '<li>' + r_answers[i].title + '</li>'
      return res
    },
  },
  created() {
  }
}
</script>

<style scoped>


.errorClass {
  background: lightpink;
}

.answerRight {
  background: lightgreen;
}
</style>