<template>
  <div>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Инструкции" active>
          <b-card-text>Инструкции по работе с системой для администратора</b-card-text>
        </b-tab>
        <b-tab title="Загрузить тест">
          <upload-tests/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import UploadTests from "@/components/admin/uploadTests";
export default {
  name: "Admin",
  components: {UploadTests}
}
</script>

<style scoped>

</style>