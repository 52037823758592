<template>
  <b-sidebar :id="'sidebar-right' + id_test" title=""
             width="100%" right backdrop shadow no-close-on-esc no-close-on-backdrop>
    <div class="row">
      <!--      {{ questions }}-->
      <div class="list_questions col-12">
        <div v-for="(question, index) in questions" :key="index">
          <div class="question" @click="selectQuestion = question; quest_index = index"
               v-b-modal="'modal-question' + question.id">
            <div class="question_number non_selections"></div>
            <div class="question_title non_selections">{{ question.title }}</div>
          </div>
        </div>
      </div>
      <div class="col-8">
        <question :question="selectQuestion" :ix_quest="quest_index" :mod="mod" @next_question="NextQuestion"/>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import {mapGetters} from "vuex";
import Question from "@/components/testing/question";

export default {
  name: "testingSB",
  components: {Question},
  props: ['questions', 'mod', 'id_test'],
  data() {
    return {
      selectQuestion: {},
      quest_index: '',
    }
  },
  methods: {
    ...mapGetters(['getUrl', 'getAccount']),
    async ToAnswer() {
      let url = this.getUrl() + 'testing/check-answer/?answer=' + this.selected + '&mode=' + this.mod
      if (this.selectedM.length)
        url = this.getUrl() + 'testing/check-answer/?answer=' + this.selectedM[0] + '&mode=' + this.mod
      const res = await fetch(url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Accept': 'application/json',
              "authorization": "JWT " + localStorage.getItem('access_token')
            },
          }
      );
      const questions = await res.json()
      let answers = questions[0].answers
      for (let i = 0; i < answers.length; i++) {
        if (this.selectedM.length) {
          let check_multi = this.selectedM.includes(answers[i].id)
          if (answers[i].is_right && check_multi) {
            document.getElementById('answer' + answers[i].id).parentElement.style.backgroundColor = '#9ec99e'
            let parent = document.getElementById('answer' + answers[i].id).parentElement
            let liFirst = document.createElement('div');
            liFirst.style.margin = 'auto'
            liFirst.innerHTML = '<strong>Правильно</strong>';
            parent.append(liFirst)
          } else if (answers[i].is_right && !check_multi) {
            document.getElementById('answer' + answers[i].id).parentElement.style.backgroundColor = '#9ec99e'
          } else if (!check_multi) {
            document.getElementById('answer' + answers[i].id).parentElement.style.backgroundColor = '#e7b1b1'
          } else {
            document.getElementById('answer' + answers[i].id).parentElement.style.backgroundColor = '#e7b1b1'
            let parent = document.getElementById('answer' + answers[i].id).parentElement
            let liFirst = document.createElement('div');
            liFirst.style.margin = 'auto'
            liFirst.innerHTML = '<strong>Не правильно</strong>';
            parent.append(liFirst)
          }
        } else {
          if (answers[i].is_right) {
            document.getElementById('answer' + answers[i].id).parentElement.style.backgroundColor = '#9ec99e'
            if (this.selected === answers[i].id) {
              let parent = document.getElementById('answer' + answers[i].id).parentElement
              let liFirst = document.createElement('div');
              liFirst.style.margin = 'auto'
              liFirst.innerHTML = '<strong>Правильно</strong>';
              parent.append(liFirst)
            }
          } else {
            document.getElementById('answer' + answers[i].id).parentElement.style.backgroundColor = '#e7b1b1'
            if (this.selected === answers[i].id) {
              // <b-icon icon="check-square" scale="2" variant="success"></b-icon>
              let parent = document.getElementById('answer' + answers[i].id).parentElement
              let liFirst = document.createElement('div');
              liFirst.style.margin = 'auto'
              liFirst.innerHTML = '<strong>Не правильно</strong>';
              parent.append(liFirst)
            }
          }
        }
      }


      this.disabledAnswer = true
      this.next = true
    },
    methodThatForcesUpdate() {
      this.currentQuestion = 1
      this.selected = ''

      this.next = false
      this.disabledAnswer = false
      location.reload()

    },
    NextQuestion(data) {
      console.log(data.index)
      if (data.index === this.questions.length)
        console.log('Question not')
      else {
        this.selectQuestion = this.questions[data.index]
        this.quest_index = data.index
      }
    }
  },
}
</script>

<style lang="scss">
.list_questions {
  border-right: 1px dotted black;
  text-align: left;

  .question {
    color: #1b72af;
    cursor: pointer;
    margin: 5px 2%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    border-bottom: 1px dotted lightgray;

    .question_number {
      color: black;
      padding: 2px;

    }

    .question_title {
      margin-left: 15px;
    }
  }

}
</style>