const convertDate = {
    methods: {
        secondToMinutes(data) {
            let d = Number(data);
            var h = Math.floor(d / 3600);
            var m = Math.floor(d % 3600 / 60);
            var s = Math.floor(d % 3600 % 60);

            var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
            var mDisplay = m > 0 ? m + (m == 1 ? " мин, " : " мин, ") : "";
            var sDisplay = s > 0 ? s + (s == 1 ? " сек" : " сек") : "";
            return hDisplay + mDisplay + sDisplay;

            return data / 60
        },
        f_dd_mm_yyyy(date) {
            if (date) {
                let yyyy = date.slice(0, 4)
                let mm = date.slice(5, 7)
                let dd = date.slice(8, 10)
                return dd + '.' + mm + '.' + yyyy
            } else
                return '-'
        },
        f_dd_mm_yyyy_time(date) {
            if (date) {
                let yyyy = date.slice(0, 4)
                let mm = date.slice(5, 7)
                let dd = date.slice(8, 10)
                let t = date.slice(11, 16)
                return dd + '.' + mm + '.' + yyyy + ' ' + t
            } else if (date === '-')
                return '-'
            else
                return '-'

        },
        timeConverter(UNIX_timestamp) {
            if (UNIX_timestamp) {
                var a = new Date(UNIX_timestamp * 1000);
                var months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
                var year = a.getFullYear();
                var month = months[a.getMonth()];
                var date = a.getDate();
                var hour = a.getHours();
                var min = a.getMinutes();
                var sec = a.getSeconds();
                var time = date + '.' + month + '.' + year + ' ' + hour + ':' + min + ':' + sec;
                return time;
            } else {
                return ''
            }

        },
        datePlusOneDay(date) {
            if (date) {
                let d = date.split('-')
                let result = new Date(parseInt(d[0]), parseInt(d[1] - 1), parseInt(d[2]));
                result.setDate(result.getDate() + 2)
                return (result.toISOString()).split('T')[0]
            } else {
                return ''
            }

        },
        dd_mm_yyyy_To_Timestamp(date) {
            return new Date(date).getTime() / 1000
        }
    }

}
export default convertDate