<template>
  <b-card-text>
    <div>
      <b-card no-body>
        <b-tabs pills card vertical nav-wrapper-class="w-25">
          <b-tab title="Tab 1" active>
            <upload-textarea/>
          </b-tab>
          <b-tab title="Tab 2">
            <b-card-text>
              <div>
                (q)3. На какие из перечисленных ОПО распространяются требования ФНП ПС?
                (a)
                На ОПО, где эксплуатируются подъемные сооружения, установленные в шахтах.
                На ОПО, где эксплуатируются эскалаторы.
                На ОПО, где эксплуатируются грузовые электрические тележки, передвигающиеся по надземным рельсовым путям совместно с кабиной управления.
                На ОПО, где эксплуатируются краны для подъема и опускания затворов гидротехнических сооружений без осуществления зацепления их крюками, оборудованные единственным механизмом подъема и не имеющие механизма передвижения крана.
                На ОПО, где эксплуатируются ПС, установленные на судах и иных плавучих средствах.
                (q)4. В каком документе содержатся результаты работы комиссии, принимающей решение о возможности пуска ПС в работу?
                (a)
                В акте пуска ПС в работу.
                В паспорте ПС.
                В ППР.
                В руководстве (инструкции) по эксплуатации ПС.
                (q)5. На какой высоте над уровнем нижней посадочной площадки (земли) должен находиться груз на неподвижном грузонесущем устройстве при статических испытаниях строительного подъемника?
                (a)
                Не более 250 мм.
                Не более 50 мм.
                Не более 200 мм.
                Не более 150 мм.
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Tab 3">
            <b-card-text>Tab contents 3</b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </b-card-text>
</template>

<script>
import UploadTextarea from "@/components/admin/uploadModuls/uploadTextarea";
export default {
  name: "uploadTests",
  components: {UploadTextarea}
}
</script>

<style scoped>

</style>