<template>
  <div class="home">
    <div>
      <b-card no-body>
        <b-tabs pills card vertical class="firstTitle">
          <b-tab title="Выберите один из доступных тестов" active>
            <b-card-text>Tab contents 1</b-card-text>
          </b-tab>
          <SubscribingToTests v-for="(test, index) in tests" :key="index" :test="test"/>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<style lang="scss">
.firstTitle {
  li:first-child {
    .nav-link{
      color: black;
      background: lightgray;
      cursor: auto;
    }
  }

}
</style>

<script>


import SubscribingToTests from "@/components/testing/SubscribingToTests";
import {mapGetters} from "vuex";

export default {
  name: 'Home',
  components: {SubscribingToTests},
  data() {
    return {
      tests: [],
      status: 'not_accepted'
    }
  },
  methods: {
    ...mapGetters(['getUrl', 'getAccount']),
    async getTests() {
      const res = await fetch(this.getUrl() + 'testing/',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Accept': 'application/json',
              "authorization": "JWT " + localStorage.getItem('access_token')
            },
          }
      );
      const tests = await res.json()
      this.tests = tests

    },
  },
  created() {
    this.getTests()
  }

}
</script>
