<template>
  <div class="timer non_selections">{{ secondToMinutes(currentTime) }}</div>
</template>

<script>
import convertDate from "@/mixins/convertDate";

export default {
  mixins: [convertDate],
  props: ['time'],
  name: "timer",
  data() {
    return {
      currentTime: 1200,
      timer: null,
    }
  },
  mounted() {
    this.startTimer()
  },
  destroyed() {
    this.stopTimer()

  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
  },
  watch: {
    currentTime(time) {
      if (time === 0) {
        this.stopTimer()
      }
    }
  },
  created() {
    if (this.time)
      this.currentTime = this.time
  }
}
</script>

<style>
.timer {
  font-size: 24px;
  text-align: center;
  width: 200px;
  color: white;
  border: 2px solid white;
  border-radius: 5px;
  padding: 10px;
}
</style>