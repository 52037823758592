<template>
  <div id="app" class="non_selections">
    <header>
      <div class="logo">
        <img class="img-fluid" src="https://bmipk.ru/img/logo_bmipk.c85ed1f8.jpg">
      </div>
      <div class="midl">
        ПОРТАЛ ТЕСТИРОВАНИЯ ЧОУ «Межотраслевой институт»
        В ОБЛАСТИ ПРОМЫШЛЕННОЙ БЕЗОПАСНОСТИ, БЕЗОПАСНОСТИ ГИДРОТЕХНИЧЕСКИХ СООРУЖЕНИЙ,
        БЕЗОПАСНОСТИ В СФЕРЕ ЭЛЕКТРОЭНЕРГЕТИКИ
      </div>
      <div class="login" v-if="!auth">
        <b-form @submit="logIn" class="form_login">
          <div><label class="sr-only" for="inline-form-input-name">Логин</label>
            <b-form-input
                id="inline-form-input-name"
                v-model="login"
                class="mb-2 mr-sm-2 mb-sm-0"
            ></b-form-input>
          </div>
          <div>
            <label class="sr-only" for="inline-form-input-username">Пароль</label>
            <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-input id="inline-form-input-username" type="password" v-model="password"></b-form-input>
            </b-input-group>
          </div>
          <div>
            <!--            <b-button type="submit" variant="primary">Save</b-button>-->
            <!--            <b-button @click="logIn" variant="primary">Вход</b-button>-->
            <b-button type="submit" variant="primary">Submit</b-button>
          </div>
        </b-form>
        <b-alert
            :show="dismissCountDown"
            variant="warning"
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
        >
          <p>{{ text }}</p>

        </b-alert>
      </div>
      <div class="logout" v-else>
        <b-button v-if="auth" @click="logOut">Выйти</b-button>

      </div>

    </header>
    <div id="nav">
      <router-link v-if="auth" to="/testing">Тесты</router-link>
    </div>
    <router-view/>
    <footer></footer>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "app",
  components: {},
  data() {
    return {
      auth: false,
      login: "",
      password: "",
      dismissSecs: 3,
      dismissCountDown: 0,
      text: ''
    }
  },
  methods: {
    ...mapGetters(['getUrl', 'getAccount']),
    async logIn(event) {
      event.preventDefault()
      let data = {username: this.login, password: this.password}
      const res = await fetch(this.getUrl() + 'auth/jwt/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Accept': 'application/json',
        },
        body: JSON.stringify(data)
      });
      const response = await res.json()
      console.log(response)

      if (response.access) {
        localStorage.setItem('access_token', response.access) // ложим токен в хранилище
        this.fetchAccount
        this.auth = true
        window.location = '/'
      } else if (response.detail) {
        console.log('No active account found with the given credentials')
        this.text = response.detail
        this.showAlert()
      }


    },
    async logOut(event) {
      event.preventDefault()
      localStorage.removeItem('access_token')
      window.location = '/'
    },
    getUsername(account) {
      if (account.length)
        return account[0].last_name + ' ' + account[0].first_name
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
  },
  computed: {
    ...mapActions(['fetchAccount'])
  },
  created() {
    if (localStorage.getItem('access_token')) {
      this.fetchAccount
      this.auth = true
    }
  }

}

</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f4f7fc;
}

.tech_info {
  border: 1px solid #0066ae;
  border-radius: 5px;
}

.non_selections {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

header {
  width: 100%;
  max-height: 800px;
  background: #0066ae;
  display: flex;
  padding: 10px;

  .logo {
    width: 10%;
  }

  .midl {
    width: 60%;
    color: white;
  }

  .login {
    width: 50%;

    .form_login {
      display: flex;

      div {
        margin: auto 2px;

      }
    }
  }
}

#nav {
  padding: 30px;
  background: white;
  box-shadow: 0 3px 10px #8a8888;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
