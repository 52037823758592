<template>
  <b-tab :title="test.test.title">
    <div class="card" style="padding: 5px">
      <div>{{ test.test.title }}</div>
      <div>Тестирование доступно до {{ test.subscribe_to }}</div>
    </div>

    <b-card-text style="display: flex; width: 100%">
      <div class="card" style="width: 50%">
        <b-form-group label="Выберите режим тестирования" v-slot="{ ariaDescribedby }">
          <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="1">
            Тренеровка
          </b-form-radio>
          <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="2">
            Экспрес тест
          </b-form-radio>
        </b-form-group>
        <div v-if="selected == 1" class="description">
          Все вопросы
        </div>
        <div v-if="selected == 2" class="description">
          20 случайных вопросов, и 20минут времени
        </div>
        <b-button v-if="selected==2" :disabled="!selected.length" variant="success" @click="start=true"
                  v-b-modal="'modal-center' + test.test.id">
          Начать
        </b-button>
        <b-button v-if="selected==1" :disabled="!selected.length" variant="success" @click="getTests(1)"
                  v-b-toggle="'sidebar-right'+ test.test.id">
          Начать
        </b-button>
        <!--        <button @click="getResult">dsfsdf</button>-->
        <!--        <div class="mt-3">Selected: <strong>{{ selected }}</strong></div>-->
      </div>
      <div class="card" style="width: 50%" v-if="selected == 2">
        <result-view-r :results="results"/>
      </div>
    </b-card-text>
    <div>
      <testing-r :available_time="test.available_time" :id_test="test.test.id" :start_test="start"
                 @reloadResult="getResultTests()"
                 @getResult="getResult"/>
      <testing-s-b :questions="questions" :mod="selected" :id_test="test.test.id"/>
    </div>
  </b-tab>
</template>

<script>
import {mapGetters} from "vuex";
import TestingSB from "@/components/testing/testingSB";
import TestingR from "@/components/testing/testingR";
import ResultViewR from "@/components/testing/resultViewR";

export default {
  name: "SubscribingToTests",
  props: ['test'],
  components: {ResultViewR, TestingR, TestingSB,},
  data() {
    return {
      start: false,
      selected: '',
      selected_subscribing: '',
      results: [],
      questions: [],
      t_result: []
    }
  },
  watch: {
    selected: function () {
      if (this.selected == 2)
        this.getResultTests()
    }
  },
  methods: {
    ...mapGetters(['getUrl', 'getAccount']),
    async getTests(mode) {
      let appUrl;
      if (mode === 1)
        appUrl = 'testing/get-question/?subscribing=' + this.test.test.id + '&mode=' + this.selected
      else if (mode === 2)
        appUrl = 'testing/get-question-result/?subscribing=' + this.test.test.id + '&mode=' + this.selected
      const res = await fetch(this.getUrl() + appUrl,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Accept': 'application/json',
              "authorization": "JWT " + localStorage.getItem('access_token')
            },

          }
      );
      this.questions = await res.json()

    },
    async getResultTests() {
      let appUrl = 'testing/get-result-test/?id=&subscribing_to_tests=&subscribing_to_tests__test=' + this.test.test.id
      const res = await fetch(this.getUrl() + appUrl,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Accept': 'application/json',
              "authorization": "JWT " + localStorage.getItem('access_token')
            },
          }
      );
      this.results = await res.json()
    },
    getResult() {
      this.getResultTests()
      this.start = false
      this.questions = []
      let sort_result = []
      for (let i = 0; i < this.results.length; i++)
        sort_result.push(this.results[i].id)
      sort_result.sort((a, b) => a - b)
      this.$bvModal.show('modal-center-detail' + sort_result[sort_result.length - 1])
    }
  }
}
</script>

<style scoped>

</style>