<template>
  <b-modal :id="'modal-center' + id_test" size="xl" no-close-on-backdrop hide-footer centered>
    <template #modal-header="{ hide }">
      <div style="width: 100%; display: flex; flex-direction: column; align-items: flex-end">
        <timer :time="available_time*60"/>
        <b-button class="endTestBtn" @click="endTest">Завершить тест</b-button>
      </div>
      <div>
      </div>
    </template>
    <div v-if="t_questions.length">
      <b-pagination class="non_selections"
                    pills
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    hide-ellipsis
                    hide-goto-end-buttons
                    :limit="rows"
      ></b-pagination>
      <div v-for="(question,index) in t_questions">
        <question-r v-if="currentPage-1 === index" :question="question"
                    :last_index="t_questions.filter(elem => elem.is_right === null)"
                    @ToAnswer="ToAnswer" @NextQuestion2="NextQuestion2"
                    @endTest="endTest()"/>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import QuestionR from "@/components/testing/questionR";
import Timer from "@/components/testing/timer";

export default {
  name: "testingR",
  components: {Timer, QuestionR},
  props: ['id_test', 'start_test', 'available_time'],
  data() {
    return {
      t_questions: [],
      perPage: 1,
      currentPage: 1,
      rows: 20
    }
  },
  watch: {
    start_test: function () {
      if (this.start_test) {
        this.currentPage = 1
        this.getTestsR()
      }
    },
    currentPage: function () {
      let aa = document.getElementsByClassName('page-link')
      for (let i = 1; i < aa.length - 1; i++) {
        if (this.t_questions[i - 1].is_right != null)
          aa[i].className += " MyClass"
      }
    }
  },
  methods: {
    ...mapGetters(['getUrl', 'getAccount']),
    async getTestsR() {
      let appUrl = 'testing/get-question-result/?subscribing=' + this.id_test + '&mode=2'
      const res = await fetch(this.getUrl() + appUrl,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Accept': 'application/json',
              "authorization": "JWT " + localStorage.getItem('access_token')
            },

          }
      );
      this.t_questions = await res.json()
      this.rows = this.t_questions.length
      for (let i = 0; i < this.t_questions.length; i++) {
        this.t_questions[i]['selected'] = {}
      }

    },
    async ToAnswer(data) {
      let url = this.getUrl() + 'testing/check-answer-result/?answer=' + data.selected + '&id_question=' + data.question
      const res = await fetch(url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Accept': 'application/json',
              "authorization": "JWT " + localStorage.getItem('access_token')
            },
          }
      );
      const response = await res.json()
      let quest = this.t_questions.find(elem => elem.id === data.question)

      let is_right
      let data_selected = data.selected.split(',')
      for (let i = 0; i < data_selected.length; i++) {
        if (response[0].answers.find(elem => elem.id == data_selected[i]).is_right)
          is_right = true
        else {
          is_right = false
          break;
        }
      }
      quest.is_right = is_right
      this.NextQuestion2()

    },
    NextQuestion2() {
      let tt = this.t_questions.filter(elem => elem.is_right == null)
      if (tt.length && this.currentPage < this.rows) {
        this.currentPage++
        if (this.t_questions[this.currentPage - 1].is_right != null && this.currentPage < this.rows)
          this.currentPage++
      } else if (!tt.length)
        this.endTest()
      else
        this.currentPage = this.t_questions.findIndex(elem => elem.id === this.t_questions.filter(elem => elem.is_right == null)[0].id) + 1
    },
    endTest() {
      this.$bvModal.hide('modal-center' + this.id_test)
      this.$emit('getResult')
    }

  },
  mounted() {

  }
}
</script>

<style lang="scss">
.endTestBtn {
  margin-top: 5px;
  width: 200px
}

.question_title {
  font-size: 24px;
}

.MyClass {
  background: black !important;
}
</style>