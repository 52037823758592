<template>
  <table class="table table-bordered" style="text-align: center">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Оценка</th>
      <th scope="col">???</th>
      <th scope="col">Подробно</th>
    </tr>
    </thead>
    <tbody v-for="(result, index) in results" :key="index">
    <tr style="text-align: center">
      <th scope="row">{{ result.id }}</th>
      <td>{{ result.result }} %</td>
      <td>{{ secondToMinutes(result.time_result) }}</td>
      <td>
        <b-button v-b-modal="'modal-center-detail' + result.id">Подробно</b-button>
        <result-detail :result="result"/>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import ResultDetail from "@/components/testing/resultDetail";
import convertDate from "@/mixins/convertDate";
export default {
  mixins:[convertDate],
  name: "resultViewR",
  components: {ResultDetail},
  props: ['results']
}
</script>

<style scoped>

</style>