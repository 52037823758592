<template>
  <b-modal :id="'modal-question' + question.id" size="xl" centered>
    <template #modal-footer>
      <div class="footer_modal">
        <b-button class="btn_to_answer" v-if="show" @click="ToAnswer">Ответить</b-button>
        <b-button class="btn_to_answer" v-else @click="NextQuestion">Дальше</b-button>
        <b-button class="btn_to_answer" v-if="show" @click="NextQuestion">Пропустить</b-button>
      </div>
    </template>
    <div class="question_modal">
      <div class="title_question non_selections">
        <div class="number_quest"></div>
        <div style="margin-left: 5px">{{ question.title }}</div>
      </div>
      <hr>
      <div class="non_selections">
        <div v-if="question.multiple_choice"
             class="container_answers">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="selectedM"
                :aria-describedby="ariaDescribedby"
                name="flavour-2"

            >
              <b-form-checkbox :id="'answer' + answer.id"
                               v-for="(answer, index) in question.answers"
                               class="answer"
                               :value="answer.id"
                               :disabled="disabledAnswer"
                               :style="coloringAnswer(answer.id)"
              >
                {{ answer.title }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
          <div class="mt-3">Selected: <strong>{{ selectedM }}</strong></div>
        </div>
        <div v-else
             class="container_answers">
          <b-form-group label="" v-slot="{ ariaDescribedby }">
            <b-form-radio :id="'answer' + answer.id" v-for="(answer, index) in question.answers"
                          :key="answer.id"
                          class="answer"
                          :disabled="disabledAnswer"
                          v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios"
                          :value="answer.id"
                          :style="coloringAnswer(answer.id)"
            >
              {{ answer.title }}
            </b-form-radio>
          </b-form-group>
        </div>
      </div>
      <div v-if="alert.no_selected_answer && !(this.selected || this.selectedM.length)" class="alert">
        Выберите хотя бы один вариант ответа
      </div>
    </div>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "question",
  props: ['question', 'ix_quest', 'mod'],
  data() {
    return {
      show: true,
      responsequestion: {},
      selected: '',
      selectedM: [],
      next: false,
      disabledAnswer: false,
      alert: {
        no_selected_answer: false
      }
    }
  },
  watch: {
    question: function () {
      this.responsequestion = {}
      this.selected = ''
      this.selectedM = []
      this.disabledAnswer = false
      this.next = false
      this.show = true
      this.alert.no_selected_answer = false
    }
  },
  methods: {
    ...mapGetters(['getUrl', 'getAccount']),

    async ToAnswer() {
      if (this.selected || this.selectedM.length) {
        let url = this.getUrl() + 'testing/check-answer/?answer=' + this.selected + '&mode=' + this.mod
        if (this.selectedM.length)
          url = this.getUrl() + 'testing/check-answer/?answer=' + this.selectedM[0] + '&mode=' + this.mod
        const res = await fetch(url,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Accept': 'application/json',
                "authorization": "JWT " + localStorage.getItem('access_token')
              },
            }
        );
        const questions = await res.json()
        this.responsequestion = {...this.question}
        this.responsequestion.answers = []
        this.responsequestion.answers = questions[0].answers
        this.show = false
        this.alert.no_selected_answer = false
      } else {
        this.alert.no_selected_answer = true
      }
    },
    coloringAnswer(answer_id) {
      if (typeof this.responsequestion.id == 'undefined')
        return 'background-color: lightgray'
      else {
        let aa = this.responsequestion.answers.find(item => item.id == answer_id)
        if (aa.is_right)
          return 'background-color: lightgreen'
        else
          return 'background-color: lightpink'
      }
    },
    NextQuestion() {
      this.$emit('next_question', {index: this.ix_quest + 1})
    }
  },

}
</script>

<style scoped lang="scss">
.question_modal {
  min-height: 500px;
}

.title_question {
  font-size: 20px;
  display: flex;
  justify-content: left;
  text-align: left;
  -webkit-touch-callout: none;
}

.container_answers {
  height: auto;



}

.footer_modal {
  width: 100%;
  display: flex;

  .btn_to_answer {
    width: 100%;
  }

  .btn_to_skip {
    width: 150px;
  }
}

.alert {
  background-color: lightpink;
}

.answer {
  display: flex;
  align-items: center;
  height: auto;
  width: 100%;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px;
  input{
    width: 45px;
    height: 45px;
  }

}

</style>