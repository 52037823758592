<template>
  <div class="question_t non_selections">
    <div class="question_title">{{ question.question.title }}</div>
    <hr>
    <div class="question_container_answers">
      <div v-if="question.question.multiple_choice">
        <div class="answer" v-for="(answer,index) in question.question.answers" :key="index">
          <b-form-checkbox :disabled="question.is_right != null" class="answer_checkbox" v-model="selected"
                           :value="{a:answer.id}"> {{ answer.title }}
          </b-form-checkbox>
        </div>
      </div>
      <div v-else>
        <div class="answer" v-for="(answer,index) in question.question.answers" :key="index">
          <b-form-radio :disabled="question.is_right != null" class="answer_radio" v-model="selected"
                        :value="{a:answer.id}">{{ answer.title }}
          </b-form-radio>
        </div>
      </div>
    </div>
    <div class="btn">
      <b-button variant="outline-success" v-if="question.is_right == null" :disabled="checkSelected()"
                @click="answerTo">Ответить
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "questionR",
  props: ['question', 'last_index', 'index'],
  data() {
    return {
      selected: [],
    }
  },
  watch: {},
  methods: {
    answerTo() {
      let selected = ''
      if (Array.isArray(this.selected))
        for (let i = 0; i < this.selected.length; i++) {
          if (i < this.selected.length - 1)
            selected += this.selected[i].a + ','
          else
            selected += this.selected[i].a
        }
      else
        selected = selected += this.selected.a
      this.$emit('ToAnswer', {
        question: this.question.id,
        selected: selected
      })
    },
    nextQuestion() {
      this.$emit('NextQuestion2')
    },
    checkSelected() {
      console.log(this.selected)
      if (this.selected.length || this.selected.a)
        return false
      return true
    }
  },
}
</script>

<style lang="scss">
.question_t {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .btn {
    width: 100%;

    button {
      width: 100%;
    }
  }
}

.question_title {
  font-size: 20px;
}

.answer_radio, .answer_checkbox {
  display: flex;
  align-items: center;
  justify-content: left;
  background: #f6f6f6;

  margin-top: 5px;

  input {
    margin-left: 10px;
    width: 25px;
    height: 25px;
  }

  label {
    margin-left: 10px;
    width: 100%;
    padding: 15px;
    cursor: pointer;

  }
}

</style>